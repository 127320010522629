import Swiper from "swiper/bundle";

export const caseSwiper = () => {
  const caseSwiper = new Swiper('.case-swiper-container', {
    loop: true,
    speed: 500,
    spaceBetween: 20,
    slidesPerView: 1.1,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {

      575: {
        spaceBetween: 40,
        slidesPerView: 1.3245,
      },
      // 768: {
      //   slidesPerView: 2.75,
      // },
      // 1080: {
      //   slidesPerView: 3.25,
      // },
      // 1280: {
      //   slidesPerView: 3.75,
      // },
    },
  });
}
