export const splitText = () => {
  const targetEls = document.querySelectorAll(".js-split");
  const targetElsArr = Array.prototype.slice.call(targetEls);

  targetElsArr.forEach((targetEl) => {
    const splitChars = targetEl.innerHTML.trim().split("");
    const splittedContent = splitChars.reduce((acc, curr) => {
      curr = curr.replace(/\s+/, "&nbsp;");
      return `${acc}<span>${curr}</span>`;
    }, "");

    targetEl.innerHTML = splittedContent;
  });
};
