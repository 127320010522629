export const accordion = () => {
  jQuery(function () {
    const question = jQuery(".js-faq-question"); //質問部分のクラスを取得
    const answer = jQuery(".js-faq-answer"); //答え部分のクラスを取得
    answer.hide(); //答え部分を隠す
    const btn = jQuery(".js-faq-btn"); //開閉ボタンのクラスを取得

    question.on("click", function () {
      jQuery(this).next(answer).slideToggle(200);
      jQuery(this).children(btn).toggleClass("is-open"); // 開閉ボタンis-openクラスをつける。
    });


    const jsToggle = jQuery(".js-toggle");
    jsToggle.on("click", function () {
      jQuery(this).toggleClass("is-active");
    });
  });
}
