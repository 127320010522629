import Swiper from "swiper/bundle";

export const memberSlider = () => {
  const memberSlider = new Swiper(".member-slider", {
    loop: true,
    spaceBetween: 24,
    slidesPerView: 3,
    speed: 4000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },

    breakpoints: {
      600: {
        slidesPerView: 4,
      },

      750: {
        spaceBetween: 32,
        slidesPerView: 5,
      },
      1200: {
        spaceBetween: 54,
        slidesPerView: 8,
      },
    },
  });
};
