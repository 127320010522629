import Swiper from "swiper/bundle";

export const fadeSlider = () => {
  const fadeSliderEls = document.querySelectorAll(".fade-slider");
  const fadeSliderCount = fadeSliderEls.length;

  for (let i = 1; i <= fadeSliderCount; i++) {
    const slider = new Swiper(".fade-slider", {
      loop: true,
      speed: 1500,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
      },
    });
  }
};
