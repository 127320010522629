export const hamburger = () => {
  jQuery(function () {
    jQuery('.nav-sp_hamburger').click(function () {
      jQuery(this).toggleClass('active');

      if (jQuery(this).hasClass('active')) {
        jQuery('.nav-sp_menu').addClass('active');
      } else {
        jQuery('.nav-sp_menu').removeClass('active');
      }
    });
  });
}
