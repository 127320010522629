export const inview = (selector, bottomMargin) => {
  const inviewEls = document.querySelectorAll(selector);
  const inviewElsArr = Array.prototype.slice.call(inviewEls);

  const cb = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("js-inview-active");
        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    rootMargin: `-5% 0px ${bottomMargin}`,
  };

  const io = new IntersectionObserver(cb, options);
  io.POLL_INTERVAL = 100;

  inviewElsArr.forEach((el) => {
    io.observe(el);
  });
};
