export const megamenu = () => {
  jQuery(function () {
    // ①マウスをボタンに乗せた際のイベントを設定
    jQuery('.nav-header .nav_list li').hover(function () {
      // ②乗せたボタンに連動したメガメニューをスライドで表示させる
      jQuery(this).find('.megamenu').stop().slideDown();
      // ③マウスをボタンから離した際のイベントを設定
    }, function () {
      // ④マウスを離したらメガメニューをスライドで非表示にする
      jQuery(this).find('.megamenu').stop().slideUp();
    });
  });

  jQuery(window).on('scroll', function () {

    jQuery("#blog strong").each(function () {
      var position = jQuery(this).offset().top;
      var scroll = jQuery(window).scrollTop();
      var windowHeight = jQuery(window).height();
      if (scroll > position - windowHeight) {
        jQuery(this).addClass('isActive');
      }
    });

  });
}
