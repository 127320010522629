export const header = () => {
  // 古い方のMV→あとで消す

  // #mv-firstがあるページのみで発火
  if (document.querySelector("#mv-first")) {
    const catchEl = document.querySelector(".catch");
    setTimeout(() => {
      catchEl.classList.add("is-active");
    }, 1500);

    const mvFirst = document.getElementById("mv-first");
    const mvSecond = document.getElementById("mv-second");
    const mvThird = document.getElementById("mv-third");
    const mvVideo = document.getElementById("mv-video");
    const mvSkipBtn = document.getElementById("mv-skip-btn");

    setTimeout(() => {
      mvFirst.classList.add("is-active");
    }, 1500);

    setTimeout(() => {
      mvSecond.classList.add("is-active");
    }, 6000);

    // setTimeout(() => {
    //   mvVideo.play();
    // }, 7000);

    setTimeout(() => {
      mvThird.classList.add("is-active");
    }, 10500);

    mvSkipBtn.addEventListener("click", mvSkipAnimation);

    function mvSkipAnimation() {
      mvThird.classList.add("is-active");
    }
  }
};
