export const caseToclinks = () => {
  jQuery(function () {
    var countId = 1
    jQuery('.output-container .ttl-section-subpage').each(function () {
      var ttl = jQuery(this).text();
      this.id = 'ttl-' + countId;
      countId++;
      jQuery('.mv_list-link').append('<li><a href="#' + this.id + '"> ' + ttl + '</a></li>');
    })
  });
}
