export const caseModal = () => {
  if (document.querySelector(".js-case-modal")) {
    const caseModal = document.querySelector(".js-case-modal-wrapper");
    const images = document.querySelectorAll(".js-case-modal");
    const modalImg = document.querySelector(".js-case-modal-img");
    const closeBtns = document.querySelectorAll(".js-case-modal-close");
    const body = document.body;

    images.forEach((img) => {
      img.addEventListener("click", () => {
        body.style.overflow = "hidden";
        caseModal.classList.add("is-active");
        const imgSrc = img.getAttribute("data-src");
        modalImg.src = imgSrc;
      });
    });

    closeBtns.forEach((btn) => {
      btn.addEventListener("click", closeModal);
    });

    caseModal.addEventListener("click", (e) => {
      if (e.target.classList.contains("js-case-modal-wrapper")) {
        closeModal();
      }
    });

    function closeModal() {
      body.style.overflow = "auto";
      caseModal.classList.remove("is-active");
    }
  }
};
