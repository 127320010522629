import { inview } from "./modules/inview";
import { splitText } from "./modules/splitText";
import { fadeSlider } from "./modules/fadeSlider";
import { memberSlider } from "./modules/memberSlider";
import { header } from "./modules/header";
import { imgParallax } from "./modules/imgParallax";
import { caseModal } from "./modules/caseModal";
import { smoothScroll } from "./modules/smoothScroll";
import { caseSwiper } from "./modules/caseSwiper";
import { caseToclinks } from "./modules/caseToclinks";
import { megamenu } from "./modules/megamenu";
import { hamburger } from "./modules/hamburger";
import { accordion } from "./modules/accordion";

window.addEventListener("DOMContentLoaded", () => {
  inview(".js-inview", "-20%");
  inview(".js-inview-lpad-fv", "-50%");
  inview("#blog h2", "-5%");
  inview("#blog h3", "-5%");
  inview("#blog img", "-10%");
  splitText();
  fadeSlider();
  memberSlider();
  header();
  imgParallax();
  caseModal();
  smoothScroll();
  caseSwiper();
  caseToclinks();
  megamenu();
  hamburger();
  accordion();
});
