export const imgParallax = () => {
  if (document.querySelector(".js-img-parallax")) {
    const img = document.querySelector(".js-img-parallax");
    const mq = window.matchMedia("(max-width: 768px)");

    handle(mq);

    mq.addListener(handle);

    function handle(mediaQuery) {
      if (mediaQuery.matches) {
        window.addEventListener("scroll", () => {
          let value = window.scrollY - 1000;
          img.style.transform = `translate3d(0, ${value * -0.06 + "px"}, 0) scale(1.2)`;
          console.log(value);
          console.log(img.style.transform);
        });
      } else {
        window.addEventListener("scroll", () => {
          let value = window.scrollY;
          img.style.transform = `translate3d(0, ${value * -0.15 + "px"}, 0)`;
        });
      }
    }
  }
};
